import React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import FormatQuoteRoundedIcon from "@mui/icons-material/FormatQuoteRounded";

const now = new Date();

interface ReviewCardProps {
  author?: string;
  bio?: string;
  review?: string;
  color?: string;
}

export const ReviewCard = ({ author, bio, review, color }: ReviewCardProps) => (
  <Box
    sx={{
      backgroundColor: "background.default",
      minHeight: "100%",
      p: 0,
    }}
  >
    <Card
      sx={{
        bgcolor: color ? color : "#f8fafc",
        "& + &": {
          mt: 2,
        },
      }}
    >
      <CardHeader
        sx={{
          px: 0,
          pb: 0,
          pt: 1,
          alignItems: "flex-start",
        }}
        avatar={
          <Avatar
            aria-label='quote'
            color='primary'
            sx={{
              bgcolor: "#f8fafc",
              ml: 1,
            }}
          >
            <FormatQuoteRoundedIcon color='primary' />
          </Avatar>
        }
        disableTypography
      />
      <Box
        sx={{
          pb: 2,
          px: 2,
          pt: 1,
        }}
      >
        <Typography
          color='textSecondary'
          component={"blockquote"}
          variant='body1'
          sx={{
            fontStyle: "italic",
            fontSize: "14px",
          }}
        >
          {review && review}
        </Typography>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          mt: 1,
          p: 2,
        }}
      >
        <Typography color='textSecondary' variant='caption'>
          {bio && bio}
        </Typography>
      </Box>
    </Card>
  </Box>
);
