import * as React from "react";
import Grid from "@mui/material/Grid";
import FrontCover3 from "./FrontCover3";
import Typography from "@mui/material/Typography";
interface TheOverstoryTabProps {
  text?: string[];
}

const TheOverstoryTab: React.FC<TheOverstoryTabProps> = ({
  text = [
    `First there was nothing, then there was everything, and now we must start again.`,
    `The arks are our salvation, for those who understand.`,
    `In them lies our fate, if we open our eyes and believe.`,
    `There is safety, health and long life for those who enter.`,
    `Every plank breathes life, every electronic device brings hope – they’re all part of the story. Her story and our story. The understory and the overstory.`,
    `The Overstory`,
    `The Overstory is the third book in the Katja’s World Game trilogy. ‘Katja’s World Game: The Overstory’, gives us hope, but it requires that we change our life style significantly. At the same time, the characters discover that this is a small price to pay for a safe and healthy future. Keep the best of the old world, nurture it and enjoy it, and take full advantage of the benefits of the evolving new one, with its advanced technology and Artificial Intelligence.`,
  ],
}: TheOverstoryTabProps) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={8} md={8} lg={9} sx={{ pr: 4 }}>
        <Typography
          color='textSecondary'
          sx={{ mt: 3, fontStyle: "italic" }}
          variant='subtitle2'
        >
          {text[0]}
        </Typography>
        <Typography
          color='textSecondary'
          sx={{ mt: 1, ml: 3, fontStyle: "italic" }}
          variant='subtitle2'
        >
          {text[1]}
        </Typography>
        <Typography
          color='textSecondary'
          sx={{ mt: 1, ml: 3, fontStyle: "italic" }}
          variant='subtitle2'
        >
          {text[2]}
        </Typography>
        <Typography
          color='textSecondary'
          sx={{ mt: 1, ml: 3, fontStyle: "italic" }}
          variant='subtitle2'
        >
          {text[3]}
        </Typography>
        <Typography
          color='textSecondary'
          sx={{ mt: 1, fontStyle: "italic" }}
          variant='subtitle2'
        >
          {text[4]}
        </Typography>
        <Typography color='primary.main' sx={{ mt: 4 }} variant='h6'>
          {text[5]}
        </Typography>
        <Typography color='textSecondary' sx={{ mt: 3 }} variant='body1'>
          {text[6]}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
        <FrontCover3 />
      </Grid>
    </Grid>
  );
};

export default TheOverstoryTab;
