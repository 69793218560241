import * as React from "react";
import { formatDistanceToNowStrict, subHours } from "date-fns";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import CommentIcon from "@mui/icons-material/Comment";
import AccessibleLink from "./AccessibleLink";
import SocialMediaShare from "../components/SocialMediaShare";
import TagList from "./TagList";
// import { FormattedMessage } from "react-intl";

const now = new Date();

const BlogCard = (props) => {
  const projects = props.projects;

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        my: 2,
      }}
    >
      <Grid container spacing={3}>
        {projects.map((project, inx) => {
          let review =
            project.node.frontmatter.tags[0] === "Book reviews"
              ? "#f8fafc"
              : "#ffffff";
          return (
            <Grid item key={inx} md={4} xs={12}>
              <Card sx={{ bgcolor: review }}>
                <Box sx={{ m: 1 }}>
                  <CardMedia
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    {/* <GatsbyImage
                      image={thumbnail}
                      imgStyle={{ borderRadius: "4px" }}
                      alt={project.node.frontmatter.title}
                    /> */}
                  </CardMedia>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      mt: 2,
                    }}
                  >
                    {/* <Avatar src={project.node.frontmatter.title} /> */}
                    <Box sx={{ ml: 1 }}>
                      <Typography variant='h6' color='primary'>
                        <AccessibleLink to={project.node.frontmatter.slug}>
                          {project.node.frontmatter.title}
                        </AccessibleLink>
                      </Typography>
                      <Typography color='textSecondary' variant='caption'>
                        By {project.node.frontmatter.author} | Updated{" "}
                        {formatDistanceToNowStrict(
                          subHours(
                            new Date(project.node.frontmatter.date),
                            1
                          ).getTime()
                        )}{" "}
                        ago
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    pb: 2,
                    px: 3,
                  }}
                >
                  <Typography color='textSecondary' variant='body2'>
                    {project.node.excerpt}
                  </Typography>
                  <TagList
                    tags={project.node.frontmatter.tags.slice(0, 1)}
                    color={"default"}
                    langKey={project.node.frontmatter.lang}
                  />
                </Box>

                <Divider />
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    pl: 2,
                    pr: 3,
                    py: 2,
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      m: 1,
                    }}
                  >
                    <SocialMediaShare />
                  </Box>
                  <Box sx={{ flexGrow: 1 }} />
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default BlogCard;
