import * as React from "react";
import { graphql } from "gatsby";
// import HomeLayout from "../components/HomeLayout";
import Layout from "../components/layout/Layout";
import HomeHeroPage from "../components/HomeHeroPage";
import Presentation from "../components/Presentation";
import BlurbCover from "../components/BlurbCover";
import BlogCard from "../components/BlogCard";
import { getCurrentLangKey } from "../langfunctions";
import WelcomeBabbleBlock from "../components/landingBlocks/WelcomeBabble";
import CarouselCenterMode from "../components/sections/CarouselCenterMode";
import CarouselBasic from "../components/sections/CarouselBasic";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
// import UsersReviews from "../components/UsersReviews";
// markup

const IndexPage = (props) => {
  const data = props.data;
  const location = props.location;
  const url = location.pathname;
  const { langs, defaultLangKey } = data.site.siteMetadata.languages;
  const langKey = getCurrentLangKey(langs, defaultLangKey, url);
  const jsonData = data.articles.edges[0].node.articles;
  const languages = data.site.siteMetadata.languages;
  const lang = langKey;

  return (
    <Layout
      title={data.markdownRemark.frontmatter.title}
      jsonData={jsonData}
      data={data}
      languages={languages}
      location={location}
      description={data.markdownRemark.frontmatter.description}
    >
      <HomeHeroPage
        header={data.markdownRemark.frontmatter.title}
        langKey={lang}
      />
      <Presentation
        text={data.markdownRemark.frontmatter.firstbook}
        text2={data.markdownRemark.frontmatter.secondbook}
        text3={data.markdownRemark.frontmatter.thirdbook}
      />
      {data.homepage.nodes[0].frontmatter.reviews && (
        <Card sx={{ mt: 1 }}>
          <CardHeader
            title={
              <Typography component={"h2"} variant='h6' color='primary'>
                What others say
              </Typography>
            }
          />
          <CardContent>
            <CarouselCenterMode
              reviews={data.homepage.nodes[0].frontmatter.reviews}
            />
          </CardContent>
        </Card>
      )}
      <CarouselBasic reviews={data.homepage.nodes[0].frontmatter.reviews} />
      <BlurbCover
        aboutKatja={data.markdownRemark.frontmatter.sections[1]}
        title={data.markdownRemark.frontmatter.sections[0]}
        // text1={data.markdownRemark.frontmatter.sections[2]}
        description={data.markdownRemark.internal.content}
        aboutKatjaBody={data.markdownRemark.frontmatter.cardContent}
      />
      <WelcomeBabbleBlock
        content={data.markdownRemark.frontmatter.cardContent}
      />
      <BlogCard projects={lang === "en" ? data.en.edges : data.zh.edges} />
      <title>{data.markdownRemark.frontmatter.title}</title>
    </Layout>
  );
};

export default IndexPage;

/*
1. We do have two variables: id and lang which are being passed to the query. 
In the below query the lang and id variables are being defined in react component then we will passing it through the qraphql query.
2. The query is selecting the markdownRemark node.
*/

export const query = graphql`
  query TheHomeQueryAll($id: String!, $lang: String) {
    homepage: allMarkdownRemark(
      filter: { frontmatter: { lang: { eq: "en" }, id: { eq: "03" } } }
    ) {
      nodes {
        frontmatter {
          id
          slug
          title
          lang
          description
          date
          author
          headerDescription
          sections
          headers
          firstbook
          secondbook
          thirdbook
          reviews {
            author
            bio
            review
          }
        }
        internal {
          content
        }
        html
      }
    }
    markdownRemark(id: { eq: $id }, fields: { langKey: { eq: $lang } }) {
      frontmatter {
        id
        slug
        title
        lang
        description
        date
        author
        headerDescription
        sections
        headers
        firstbook
        secondbook
        thirdbook
        cardContent
      }
      internal {
        content
      }
    }
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    articles: allArticlesJson(filter: { title: { eq: "home" } }) {
      edges {
        node {
          articles {
            en
            zh
          }
        }
      }
    }
    en: allMarkdownRemark(
      limit: 3
      filter: {
        frontmatter: { lang: { eq: "en" }, template: { eq: "blogbody" } }
      }
      sort: { fields: frontmatter___id }
    ) {
      edges {
        node {
          frontmatter {
            title
            tags
            author
            slug
            lang
            date
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 335
                  placeholder: BLURRED
                  layout: CONSTRAINED
                )
              }
            }
          }
          excerpt
        }
      }
    }
    zh: allMarkdownRemark(
      limit: 3
      filter: {
        frontmatter: { lang: { eq: "zh" }, template: { eq: "blogbody" } }
      }
      sort: { fields: frontmatter___id }
    ) {
      edges {
        node {
          frontmatter {
            title
            tags
            author
            slug
            lang
            date
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 335
                  placeholder: BLURRED
                  layout: CONSTRAINED
                )
              }
            }
          }
          excerpt
        }
      }
    }
  }
`;
