import Slider from "react-slick";
import React, { useState, useRef } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box, Card } from "@mui/material";
// components
import { ReviewCard } from "./reviewCard";
import "./style.css";
import { CarouselArrows } from "../carousel";

// ----------------------------------------------------------------------

export default function CarouselBasic({ reviews }) {
  const theme = useTheme();
  const carouselRef = useRef<Slider | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    dots: false,
    arrows: false,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === "rtl"),
    beforeChange: (current: number, next: number) => setCurrentIndex(next),
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        my: 1,
      }}
    >
      <Slider ref={carouselRef} {...settings}>
        {/* {reviews &&
          reviews.author.map((item, i) => ( */}
        <ReviewCard
          // key={i + 3444}
          review={reviews.review[12]}
          bio={reviews.bio[12]}
          author={reviews.author[12]}
        />
        {/* ))} */}
      </Slider>
      {/* <CarouselArrows
        index={currentIndex}
        total={reviews.length}
        onNext={handleNext}
        onPrevious={handlePrevious}
      /> */}
    </Box>
  );
}

// ----------------------------------------------------------------------

type CarouselItemProps = {
  title: string | undefined;
  description: string | undefined;
  image: string;
};

function CarouselItem({ item }: { item: CarouselItemProps }) {
  const { image, title } = item;

  return "<Image alt={title} src={image}";
}
