import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { keyframes } from "@mui/system";
const circularMoving = keyframes`
  from {
    transform: rotate(0deg) translateX(20px) rotate(0deg)
  }
  to {
    transform: rotate(360deg) translateX(20px) rotate(-360deg)
  }
`;

interface PopUpProps {
  text: string;
  translated: string;
  mb?: string;
  ml?: string;
}

export const BoxPoint = () => (
  <Box
    sx={{
      content: '""',
      width: 0,
      height: 0,
      position: "absolute",
      borderLeft: (theme) => `10px solid ${theme.palette.primary.main}`,
      borderRight: "10px solid transparent",
      borderTop: (theme) => `10px solid ${theme.palette.primary.main}`,
      borderBottom: "10px solid transparent",
      left: "30px",
      bottom: "-15px",
      borderRadius: "25%",
    }}
  />
);

export const PopUp = ({ text, translated, mb, ml }: PopUpProps) => {
  return (
    <Box
      sx={{
        borderRadius: 2,
        p: 1,
        m: 1,
        ...(mb && { mb: mb }),
        ...(ml && { ml: ml }),
        translate: translated,
        backgroundColor: (theme) => `${theme.palette.primary.main}`,
        padding: { xs: "3px", md: "4px" },
        maxWidth: "9rem",
        animation: `${circularMoving} 8s linear infinite`,
      }}
    >
      <Box
        sx={{
          alignItems: "flex-start",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Typography
          sx={{
            color: "#ffff",
            fontSize: { xs: "11px", md: "14px", lg: "15px" },
          }}
          variant='caption'
        >
          {text}
        </Typography>
      </Box>
      <BoxPoint />
    </Box>
  );
};

export default PopUp;
