import Slider from "react-slick";
import { useRef } from "react";
import { useTheme, styled } from "@mui/material/styles";
import { ReviewCard } from "./reviewCard";
import { CarouselArrows } from "../carousel";
import React from "react";
import "./style.css";

import Box from "@mui/material/Box";

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(() => ({
  overflow: "hidden",
  position: "relative",
}));

// ----------------------------------------------------------------------

export default function CarouselCenterMode({ reviews }) {
  const carouselRef = useRef<Slider | null>(null);
  const theme = useTheme();

  const settings = {
    slidesToShow: 1,
    centerMode: true,
    centerPadding: "60px",
    rtl: Boolean(theme.direction === "rtl"),
    nextArrow: "",
    prevArrow: "",
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 1 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1, centerPadding: "0" },
      },
    ],
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <RootStyle>
      <CarouselArrows
        filled
        onNext={handleNext}
        onPrevious={handlePrevious}
        sx={{
          "& .arrow": {
            "&.left": { left: 16 },
            "&.right": { right: 16 },
          },
        }}
      >
        <Slider ref={carouselRef} {...settings}>
          {reviews &&
            reviews.author.map((item, i) => (
              <Box key={item} sx={{ p: 1 }}>
                <ReviewCard
                  review={reviews.review[i]}
                  bio={reviews.bio[i]}
                  author={reviews.author[i]}
                />
              </Box>
            ))}
        </Slider>
      </CarouselArrows>
    </RootStyle>
  );
}
