import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import PauseRounded from "@mui/icons-material/PauseRounded";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";

const Widget = styled("div")(({ theme }) => ({
  paddingRight: "2rem",
  backdropFilter: "blur(40px)",
}));

export default function MusicPlayerSlider() {
  const theme = useTheme();
  const serverAudioStreamControl = React.useRef(null);
  const duration = 32;
  const [paused, setPaused] = React.useState(true);
  const mainIconColor =
    theme.palette.mode === "dark" ? "rgb(2, 136, 209)" : "rgb(2, 136, 209)";
  const lightIconColor =
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.4)" : "rgba(0,0,0,0.4)";

  const HandlePlay = () => {
    serverAudioStreamControl.current.play();
    setPaused(!paused);
  };

  const HandlePause = () => {
    serverAudioStreamControl.current.pause();
    setPaused(!paused);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
      }}
    >
      <audio ref={serverAudioStreamControl}>
        <source src='/katja.mp3' />
      </audio>
      <Widget>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          {paused ? (
            <IconButton aria-label={"play"} onClick={HandlePlay}>
              <PlayCircleFilledWhiteOutlinedIcon
                sx={{
                  fontSize: "2rem",
                }}
                htmlColor={mainIconColor}
              />
            </IconButton>
          ) : (
            <IconButton aria-label={"pause"} onClick={HandlePause}>
              <PauseRounded
                sx={{ fontSize: "2rem" }}
                htmlColor={mainIconColor}
              />
            </IconButton>
          )}
        </Box>
      </Widget>
    </Box>
  );
}
