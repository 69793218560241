import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { StaticImage } from "gatsby-plugin-image";
import PopUp from "./PopUp";
import MediaPlayer from "../MediaPlayer";
import Roll from "react-reveal/Roll";
import Typography from "@mui/material/Typography";

export function CoverOvalShape(props) {
  return (
    <StaticImage
      src='../../images/oval.png'
      alt="The katja's world game cover in a oval shape"
      placeholder='blurred'
      layout='constrained'
      width={400}
      height={612}
    />
  );
}

export const WelcomeBabble = () => {
  const reff = React.useRef(null);
  return (
    <>
      <Grid
        direction='row'
        justifyContent='center'
        alignItems='center'
        container
        columns={12}
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          p: 3,
          position: "relative",
        }}
      >
        <Grid item xs={12} md={3} sm={2} lg={3} xl={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItem: "flex-start",
              top: "12px",
              position: "absolute",
              zIndex: 1200,
            }}
          >
            <Roll left>
              <PopUp
                translated='18% 10%'
                text='Am I alone?'
                mb='1px'
                ml='10px'
              />
            </Roll>
          </Box>
        </Grid>

        <Grid
          sx={{
            position: "relative",
            borderRadius: "50%",
          }}
          item
          xs={6}
          md={4}
          sm={5}
          lg={4}
          xl={4}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItem: "center",
            }}
          >
            <CoverOvalShape />
            <Box
              style={{ alignSelf: "center", position: "absolute" }}
              ref={reff}
            ></Box>
          </Box>
        </Grid>
        <Grid item xs={6} md={5} sm={5} lg={5} xl={5}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItem: "flex-end",
            }}
          >
            <Roll right>
              <PopUp
                translated='4% 2%'
                text='What will I forget?'
                mb='5vw'
                ml='8vw'
              />
            </Roll>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItem: "flex-start",
            }}
          >
            <Roll left>
              <PopUp
                translated='25% 50%'
                text='Is this really happening?'
                mb='1px'
                ml='1px'
              />
            </Roll>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const WelcomeBabbleBlock = ({ content }) => {
  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        minHeight: "100%",
        m: 0,
        borderColor: "divider",
        borderRadius: 2,
        borderStyle: "solid",
        borderWidth: 1,
        display: "flex",
        boxShadow: 0,
      }}
    >
      <Grid container>
        <Grid item md={8} xs={12}>
          <WelcomeBabble />
        </Grid>

        <Grid
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
          item
          md={4}
          xs={12}
        >
          <Box
            sx={{
              backgroundColor: "#f8fafc",
              p: 1,
              m: 4,
              borderRadius: 4,
            }}
          >
            {content.map((item, ix) => (
              <Typography
                color='textSecondary'
                key={ix}
                sx={{ mt: 1, ml: 3, fontStyle: "italic" }}
                variant='subtitle2'
                component='p'
              >
                {item}
              </Typography>
            ))}
            <MediaPlayer />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WelcomeBabbleBlock;
