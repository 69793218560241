import * as React from "react";
import Box from "@mui/material/Box";
import { StaticImage } from "gatsby-plugin-image";

export function Glacier() {
  return (
    <StaticImage
      src='../images/meltingglacier.jpg'
      style={{ borderRadius: "4px", margin: "4px" }}
      alt='A glacier is a large mass of ice that moves slowly down a slope.'
      placeholder='blurred'
      layout='constrained'
    />
  );
}

export function ForestFire() {
  return (
    <StaticImage
      style={{ borderRadius: "4px", margin: "4px" }}
      src='../images/forestfire.jpg'
      alt='A forest fire is a fire that burns in a forest.'
      placeholder='blurred'
      layout='constrained'
    />
  );
}

export function Flood() {
  return (
    <StaticImage
      style={{ borderRadius: "4px", margin: "4px" }}
      src='../images/flood.jpg'
      alt='flood'
      placeholder='blurred'
      layout='constrained'
    />
  );
}

export function Desert() {
  return (
    <StaticImage
      style={{ borderRadius: "4px", margin: "4px" }}
      src='../images/desert.jpg'
      alt='A photograph of the Namibian landscape is featured in the video for Child of Lockdown by Blue Bird by Peter Burdon'
      placeholder='blurred'
      layout='constrained'
    />
  );
}

export default function BasicMasonryImage() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: { xs: "row", sm: "row", md: "row", lg: "row" },
        mt: { xs: 3, sm: 3, md: 0 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "row", sm: "row", md: "column", lg: "column" },
          maxWidth: { xs: 300, sm: 600, md: 250, lg: 250 },
          mt: { xs: 0, sm: 0, md: 8, lg: 8 },
        }}
      >
        <Glacier />
        <ForestFire />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "center", md: "flex-start" },
          maxWidth: { xs: 200, sm: 250, md: 250, lg: 250 },
          mt: { xs: 0, sm: 0, md: 11, lg: 0 },
        }}
      >
        <Desert />
      </Box>
    </Box>
  );
}
