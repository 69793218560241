import * as React from "react";
import Grid from "@mui/material/Grid";
import TabPanel from "../components/TabPanel";
import { Box, Card, CardHeader } from "@mui/material";
import TheGameBeginsTab from "./TheGameBeginsTab";
import TheUnderstoryTab from "./TheUnderstoryTab";
import TheOverstoryTab from "./TheOverstoryTab";
import { useIntl } from "react-intl";

interface presentationProps {
  text?: string[];
  text2?: string[];
  text3?: string[];
}

const Presentation: React.FC<presentationProps> = ({ text, text2, text3 }) => {
  const intl = useIntl();

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        mt: { xs: 24, sm: 2, md: 1 },
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardHeader disableTypography />
            <Box>
              <TabPanel
                text1={<TheGameBeginsTab text={text} />}
                text2={<TheUnderstoryTab text={text2} />}
                text3={<TheOverstoryTab text={text3} />}
                labelTab1={intl.formatMessage({ id: "firstBookTitle" })}
                labelTab2={intl.formatMessage({ id: "secondBookTitle" })}
                labelTab3={intl.formatMessage({ id: "thirdBookTitle" })}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Presentation;
