import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Box from "@mui/material/Box";
import { Badge } from "@mui/material";

interface SimpleBoxImageProps {
  labelTab2?: string;
}

const SimpleBoxImage3: React.FC<SimpleBoxImageProps> = ({
  labelTab2 = "text 2",
}: SimpleBoxImageProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <StaticImage
        src='../images/cover3.png'
        alt='Front cover of The Overstory'
        placeholder='blurred'
        layout='fixed'
        width={240}
        height={357}
      />
    </Box>
  );
};

const FrontCover3 = (props) => {
  return (
    <>
      <Box>
        <Badge
          color='primary'
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          badgeContent={"Forthcoming ..."}
          sx={{
            "& .MuiBadge-badge": {
              borderRadius: "4px",
              padding: "13px 12px",
            },
          }}
        >
          {props.children}
          <SimpleBoxImage3 />
        </Badge>
      </Box>
    </>
  );
};

export default FrontCover3;
