import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FrontCover2 from "./FrontCover2";
import { FormattedMessage } from "react-intl";

interface TheUnderstoryTabProps {
  text?: string[];
}

const TheUnderstoryTab: React.FC<TheUnderstoryTabProps> = ({
  text = [
    `First there was nothing, now there is everything. Finally, there is
  the game.`,
    `The game, it shows what’s happening.`,
    `In its moves lies our fate, if we care to play.`,
    ` It is the game of life. We hear its sad song as we desperately yearn
  for hope.`,
    `Every move, every conquest – they’re all part of the story. Her story and our story. The understory and the overstory.`,
    `The Understory`,
    `The Understory, with its three fictional countries and their different
  climate problems, gives us choices – choices that determine our
  future.`,
  ],
}: TheUnderstoryTabProps) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={8} md={8} lg={9} sx={{ pr: 4 }}>
        <Typography
          color='textSecondary'
          sx={{ mt: 3, fontStyle: "italic" }}
          variant='subtitle2'
        >
          {text[0]}
        </Typography>
        <Typography
          color='textSecondary'
          sx={{ mt: 1, ml: 3, fontStyle: "italic" }}
          variant='subtitle2'
        >
          {text[1]}
        </Typography>
        <Typography
          color='textSecondary'
          sx={{ mt: 1, ml: 3, fontStyle: "italic" }}
          variant='subtitle2'
        >
          {text[2]}
        </Typography>
        <Typography
          color='textSecondary'
          sx={{ mt: 1, ml: 3, fontStyle: "italic" }}
          variant='subtitle2'
        >
          {text[3]}
        </Typography>
        <Typography
          color='textSecondary'
          sx={{ mt: 1, fontStyle: "italic" }}
          variant='subtitle2'
        >
          {text[4]}
        </Typography>
        <Typography color='primary.main' sx={{ mt: 4 }} variant='h6'>
          <FormattedMessage id='secondBookTitle' />
        </Typography>
        <Typography color='textSecondary' sx={{ mt: 3 }} variant='body1'>
          {text[6]}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={3}>
        <FrontCover2 />
      </Grid>
    </Grid>
  );
};

export default TheUnderstoryTab;
