import * as React from "react";
import Grid from "@mui/material/Grid";
import SimpleBoxImage from "./SimpleBoxImage";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";

interface TheGameBeginsTabProps {
  text?: string[];
}

const TheGameBeginsTab: React.FC<TheGameBeginsTabProps> = ({
  text = [
    "The Game Begins ",
    `It is 2022. What can six students at a university in Bath, England, do
  to turn the tables and save the Earth from imminent destruction? Who
  will be saved? Is technology the solution or our downfall? The past
  and present are intertwined as the six students, from China, Iran,
  Northern Ireland, Norway, The Gambia, and the U.S.A try to make sense
  of their world and save what can be saved.`,
    `Katja’s World Game: The Game Begins is the first novel in a trilogy which explores the
  natural world, the supernatural, and the world of stories and games.`,
    "Genre",
    "‘Adapted Climate Change",
    "Fantasy",
  ],
}: TheGameBeginsTabProps) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={8} md={8} lg={9} xl={9} sx={{ pr: 3 }}>
        <Typography color='primary.main' sx={{ mt: 1 }} variant='h6'>
          <FormattedMessage id='firstBookTitle' />
        </Typography>
        <Typography color='textSecondary' sx={{ mt: 1 }} variant='body1'>
          <FormattedMessage id='firstBookdescription' />
        </Typography>
        <Typography variant='body1' sx={{ mt: 1 }} color='textSecondary'>
          <FormattedMessage id='firstBookdescriptionLine2' />
        </Typography>
        <Typography variant='body1' sx={{ mt: 1 }} color='textSecondary'>
          <FormattedMessage id='firstBookdescriptionLine3' />
        </Typography>
        <Typography color='primary.main' sx={{ mt: 1 }} variant='h6'>
          <FormattedMessage id='Genre' />
        </Typography>
        <Typography color='textSecondary' sx={{ mt: 1 }} variant='body1'>
          <FormattedMessage id='AlternativeClimateChange' />
          <br /> <FormattedMessage id='Fantasy' />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
        <SimpleBoxImage />
      </Grid>
    </Grid>
  );
};

export default TheGameBeginsTab;
