import * as React from "react";
import { Box, Card, CardContent, Chip, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import MediaPlayer from "./MediaPlayer";
import { FormattedMessage } from "react-intl";

const MarkdownWrapper = styled("div")(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  "& p": {
    marginBottom: theme.spacing(1),
  },
}));

const Blockquote = styled("blockquote")(({ theme }) => ({
  margin: 1,
  padding: 0,
  fontSize: "1.5rem",
  fontStyle: "italic",
  lineHeight: "1.5",
  letterSpacing: "0.1em",
  borderLeft: `0.3rem solid ${theme.palette.text.secondary}`,
  color: theme.palette.text.primary,
  "& p": {
    marginBottom: theme.spacing(2),
  },
}));

interface DetailsListProps {
  description?: string;
  title?: string;
  aboutKatja: string[];
  aboutKatjaBody: string[];
}

const DetailsList: React.FC<DetailsListProps> = ({
  description = "default description at src/components/DetailsList",
  title = "default title at src/components/DetailsList",
  aboutKatjaBody,
  aboutKatja = ["path /pages/katjasword.md", "path /pages/katjasword.md"],
}: DetailsListProps) => {
  const descriptions = description.split(".");

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        mt: 1,
      }}
    >
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Typography
                color='primary.main'
                sx={{ m: 1 }}
                variant='h6'
                component='h2'
              >
                <FormattedMessage id='aboutKatja' />
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ my: 3 }}>
            <MarkdownWrapper>
              <Typography color='textSecondary' sx={{ m: 1 }} variant='body1'>
                <FormattedMessage id='whoIsKatja' />
              </Typography>
              <Typography color='textSecondary' sx={{ m: 1 }} variant='body1'>
                <FormattedMessage id='describeKatjaTag' />
              </Typography>
            </MarkdownWrapper>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DetailsList;
