import * as React from "react";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import DetailsList from "./DetailsList";
import Box from "@mui/material/Box";
import { StaticImage } from "gatsby-plugin-image";
import BasicMasonryImage from "./BasicMasonryImage";

export function Cover() {
  return (
    <StaticImage
      src='../images/book.png'
      alt="The katja's world game cover"
      placeholder='blurred'
      layout='constrained'
      width={400}
      height={600}
    />
  );
}

const now = new Date();

const BlurbCover = ({ title, aboutKatja, description, aboutKatjaBody }) => {
  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        my: 1,
      }}
    >
      <Grid container>
        <Grid item md={7} xs={12}>
          <DetailsList
            aboutKatja={aboutKatja}
            description={description}
            title={title}
            aboutKatjaBody={aboutKatjaBody}
          />
        </Grid>

        <Grid item md={5} xs={12}>
          <BasicMasonryImage />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BlurbCover;
