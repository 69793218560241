import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Box from "@mui/material/Box";

interface SimpleBoxImageProps {
  labelTab2?: string;
}

const SimpleBoxImage: React.FC<SimpleBoxImageProps> = ({
  labelTab2 = "text 2",
}: SimpleBoxImageProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <StaticImage
        src='../images/cover1.png'
        alt='The cover book'
        placeholder='blurred'
        layout='fixed'
        width={240}
        height={357}
      />
    </Box>
  );
};

export default SimpleBoxImage;
