import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface BasicTabsProps {
  text1?: any;
  text2?: any;
  text3?: any;
  labelTab1?: string;
  labelTab2?: string;
  labelTab3?: string;
}

export const HTMLContent = ({ content }) => (
  <Box dangerouslySetInnerHTML={{ __html: content }} />
);

const TypoTab = () => {
  const rondom = Math.random();
  return (
    <Typography variant='h1' color='initial'>
      {rondom}
    </Typography>
  );
};

const BasicTabs: React.FC<BasicTabsProps> = ({
  text1 = TypoTab,
  text2 = TypoTab,
  text3 = TypoTab,
  labelTab1 = "About Katja",
  labelTab2 = "Katja's dream",
  labelTab3 = "Katja's voice",
}: BasicTabsProps) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='switch to another book'
          variant='scrollable'
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label={labelTab1} {...a11yProps(0)} />
          <Tab label={labelTab2} {...a11yProps(1)} />
          <Tab label={labelTab3} {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {text1}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {text2}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {text3}
      </TabPanel>
    </Box>
  );
};
export default BasicTabs;
